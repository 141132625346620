import React from "react";

import Layout from "../components/Layout";
import Login from "../components/Login";

const login = () => {
    return(
        <Layout>
            <Login />
        </Layout>
    )
}

export default login;